import React from 'react';
import Layout from '../../components/motorrad/layout';
import { Link } from 'gatsby';
import SEO from '../../components/motorrad/seo';

const SiteMap = () => {
  return(
    <>
    <Layout>
    <SEO title="The BMW U.S. Rider Academy | Motorrad Sitemap"/>
      <div className='bmw-motorrad-site-map'>
        <main>
          <div className='container'>
            <div className='page-header'>Site Map</div>
            <div className="line"></div>
            <div className='row'>
              <div className='header-col'>
                <h5>01 - Classes</h5>
              </div>
              <div>
                <div>              
                  <Link to="/motorrad/schools">Classes</Link>
                </div>
              </div>
            </div>
            <div className="line"></div>
            <div className='row'>
              <div className='header-col'>
                <h5>02 - Touring</h5>
              </div>
              <div>
                <div>              
                  <Link to="/motorrad/touring">Touring</Link>
                </div>
              </div>
            </div>
            <div className="line"></div>
            <div className='row'>
              <div className='header-col'>
                <h5>03 - Private Training</h5>
              </div>
              <div>
                <div>              
                  <Link to="/motorrad/privatetraining">Private Training</Link>
                </div>
              </div>
            </div>
            <div className="line"></div>
            <div className='row'>
              <div className='header-col'>
                <h5>04 - Locations</h5>
              </div>
              <div>
                <div>              
                  <Link to="/motorrad/locations">Locations</Link>
                </div>
              </div>
            </div>
            <div className="line"></div>
            <div className='row'>
              <div className='header-col'>
                <h5>05 - Booking</h5>
              </div>
              <div>
                <div>              
                  <Link to="https://pds.eventsbmw.com/?_ga=2.68571601.1118896313.1665329309-334664269.1636725789" 
                    target="_blank" 
                    rel="noopener noreferrer">
                    Book your class
                  </Link>
                </div>
              </div>
            </div>
            <div className="line"></div>
            <div className='row multiple-subheader'>
              <div className='header-col'>
                <h5>06 - FAQ and additional information</h5>
              </div>
              <div className='faq-section'>
                <div className='faq-col-1'>
                  <div>              
                    <Link to="/motorrad/schoolfaq">School FAQ</Link>
                  </div>
                  <div>              
                    <Link to="/motorrad/instructors">Instructors</Link>
                  </div>
                  <div>              
                    <Link to="/motorrad/partners">Partners</Link>
                  </div>
                  <div>              
                    <Link to="/hotelpartners">Hotel Partners</Link>
                  </div>
                  <div>              
                    <Link to="/press">Press Room</Link>
                  </div>
                </div>
                <div className='faq-col-2'>
                  <div>              
                    <Link to="/motorrad/sitemap.xml">Site Map</Link>
                  </div>
                  <div>              
                    <Link to="https://www.bmwusa.com/privacy-policy/index.html" target="_blank" rel="noopener noreferrer">Privacy Policy</Link>
                  </div>
                  <div>              
                    <Link to="/motorrad/schoolfaq">Cancellation Policy</Link>
                  </div>
                  <div>              
                    <Link to="/accessibility">Accessibility</Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="line"></div>
            <div className='row'>
              <div className='header-col'>
                <h5>07 - BMW Motorrad Motorsport</h5>
              </div>
              <div>
                <div>              
                  <Link to="https://www.bmwmotorcycles.com/en/home.html#/filter-all" target="_blank" rel="noopener noreferrer">BMW Motorrad</Link>
                </div>
                <div>              
                  <Link to="https://www.motorsport.bmw-motorrad.com/en/home.html#/filter-all" target="_blank" rel="noopener noreferrer">BMW Motorrad Motorsport</Link>
                </div>
              </div>
            </div>
          </div> 
        </main>  
      </div>  
      </Layout>
    </>
  )
}

export default SiteMap;